<template>
  <div>
        <el-card >
          <template #header>
            <span style="font-size: small">OSW Address</span>
            <el-button type="primary" size="small" @click="createAddress" style="float: right">{{ $t('msg.basicInfo.addRecord') }}</el-button>
          </template>
          <el-table :data="myAddress">
            <el-table-column prop="personName" :label="$t('msg.address.personName')" sortable size="small"></el-table-column>
            <el-table-column prop="streetOne" :label="$t('msg.address.streetOne')" sortable size="small"></el-table-column>
            <el-table-column prop="streetTwo" :label="$t('msg.address.streetTwo')" sortable size="small"></el-table-column>
            <el-table-column prop="city" :label="$t('msg.address.city')" sortable size="small"></el-table-column>
            <el-table-column prop="state" :label="$t('msg.address.state')" sortable size="small"></el-table-column>
            <el-table-column prop="zip" :label="$t('msg.address.zip')" sortable size="small"></el-table-column>
            <el-table-column :label="$t('msg.rate.Operations')" align="center">
              <template #default="scope">
                <el-button link type="text" size="small" @click="editRow(scope.row)">Edit</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
    <el-dialog title="New Address" v-model="newAddressVisible" width="30%" center draggable>
      <el-form :data="newAddress">
        <el-form-item prop="personName" :label="$t('msg.address.personName')" style="width: 20pc">
          <el-input v-model="newAddress.personName"></el-input>
        </el-form-item>
        <el-form-item prop="streetOne" :label="$t('msg.address.streetOne')" style="width: 20pc">
          <el-input v-model="newAddress.streetOne"></el-input>
        </el-form-item>
        <el-form-item prop="city" :label="$t('msg.address.city')" style="width: 20pc" >
          <el-input v-model="newAddress.city" disabled></el-input>
        </el-form-item>
        <el-form-item prop="state" :label="$t('msg.address.state')" style="width: 20pc">
          <el-select v-model="newAddress.state" placeholder="Select">
            <el-option v-for="item in states" :key="item" :label="item" :value="item" />
          </el-select>
        </el-form-item>
        <el-form-item prop="zip" :label="$t('msg.address.zip')" style="width: 20pc">
          <el-input v-model="newAddress.zip"></el-input>
        </el-form-item>
        <el-button type="primary" style="float: right;margin-bottom: 20px;margin-right: 20px" @click="insertAddress()" v-if="editType==='add'">Submit</el-button>
        <el-button type="primary" style="float: right;margin-bottom: 20px;margin-right: 20px" @click="updateAddress()" v-else>Confirm Update</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'
export default defineComponent({
  name: 'address',
  setup() {
    const myAddress = ref([])
    const newAddressVisible = ref(false)
    const states = ref([])
    const newAddress = ref({ id: 0, personName: '', streetOne: '', streetTwo: '', city: '', state: '', zip: '' })
    const editType = ref('edit')
    const curUser = localStorage.getItem('curUser')
    const editRow = (row) => {
      newAddress.value = row
      createAddress()
      editType.value = 'edit'
    }
    const createAddress = () => {
      editType.value = 'add'
      getStates()
      newAddressVisible.value = true
    }
    const getStates = () => {
      axios.get(process.env.VUE_APP_BASE + '/getUS50States').then((response) => {
        states.value = response.data
      })
    }
    const insertAddress = () => {
      if (newAddress.value.personName === '' || newAddress.value.streetOne === '' || newAddress.value.state === '' || newAddress.value.zip === '') {
        alert('Every field in this form is required!')
        return false
      }
      const streetOne = newAddress.value.streetOne.replaceAll(',', ' ')
      const param = 'curUser=' + curUser + '&city=' + newAddress.value.city + '&personName=' + newAddress.value.personName + '&streetOne=' + streetOne +
        '&state=' + newAddress.value.state.split('-')[1] + '&zip=' + newAddress.value.zip
      const reqURL = process.env.VUE_APP_BASE + '/addOSWAddress'
      axios.post(reqURL, param).then((response) => {
        myAddress.value = response.data
        newAddressVisible.value = false
      }).catch((response) => {
        console.log(response)
      })
    }
    const updateAddress = () => {
      if (newAddress.value.personName === '' || newAddress.value.streetOne === '' || newAddress.value.state === '' || newAddress.value.zip === '') {
        alert('Every field in this form is required!')
        return false
      }
      newAddress.value.streetOne = newAddress.value.streetOne.replaceAll(',', ' ')
      const param = 'curUser=' + curUser + '&addressInSQL=' + JSON.stringify(newAddress.value)
      const reqURL = process.env.VUE_APP_BASE + '/updateById'
      axios.post(reqURL, param).then((response) => {
        myAddress.value = response.data
        newAddressVisible.value = false
      }).catch((response) => {
        console.log(response)
      })
    }
    onMounted(() => {
      const param = 'curUser=' + curUser
      const reqURL = process.env.VUE_APP_BASE + '/getOSWAddressSQL'
      axios.post(reqURL, param).then((response) => {
        myAddress.value = response.data
      }).catch((response) => {
        console.log(response)
      })
    })
    return { editRow, createAddress, updateAddress, editType, myAddress, newAddressVisible, newAddress, states, insertAddress }
  }
})
</script>
